import { XMarkIcon } from '@heroicons/react/24/solid';
import { route } from 'preact-router';
import styled from 'styled-components';

import Component from '../BaseComponent';
import Helpers from '../Helpers';
import localState from '../LocalState';
import Key from '../nostr/Key';
import { translate as t } from '../translations/Translation';

import { Button, PrimaryButton } from './buttons/Button';
import Copy from './buttons/Copy';
import Follow from './buttons/Follow';
import QRModal from './modal/QRModal';
import Identicon from './Identicon';
import Name from './Name';

const SUGGESTED_FOLLOWS = [
	[
		'npub16zlc26xk7qjlfa4dquqvvs3s5zl2yw989lprj63nfw4g7ndnuahslcevpc',
		'"Nostrfr ambassadeur"',
	],
	[
		'npub1r34nhc6nqswancymk452f2frgn3ypvu77h4njr67n6ppyuls4ehs44gv0h',
		'"Nostrfr ambassadeur"',
	],
	[
		'npub1dec0uvrwnst0q7qxwkexqv98wvnl9h74gfqmne6va6m5fhtggp0qa27mqrk',
		'"DécouvreBitcoin"',
	],
	[
		'npub1nftkhktqglvcsj5n4wetkpzxpy4e5x78wwj9y9p70ar9u5u8wh6qsxmzqs',
		'"Nostrfr ambassadeur"',
	],
	[
		'npub122revmw6027qkuhyu46cnmqvlsge6gqk9g50wxzswhnnnmq28rpsurf8av',
		'"purplepiller"',
	],
	[
		'npub1vk2u5w3fkaxtvm58zens8vsrxjmtsc9p5dwz5a6m39yzpwsxx3nsgjqs6r',
		'"Site web et podcast et éducation sur Bitcoin - https://btctouchpoint.com/"',
	],
	[
                'npub1h0a28envnl4cusyjkhp4qh79n9lf4p3zjaag2grxvnskqm9xrnasxfq3ht',
		'"purplepiller"',
	],
	[
		'npub1g8h2agg8tj820uzpuqmsl9kdcjcr46ztw0pwtyjmc6rc6rv4xtns4mjhqh',
		'"purplepiller"',
	],
	[
		'npub10v2cn47qfuw92hn0mpqzgcm6e9wwkjznu870hvdmsg7gkt8avn7q29r43s',
		'"Créateur"',
	],
	[
		'npub1kpgs5rpr3l8582ducpvuurpzmd68alzjnnkftujxxq4r5yn3dnksml3x6c',
		'"orangepiller et purplepiller"',
	],
	[
		'npub1fhlwh03chgqj5fg9yvkr3r9f08ru70ne8f05qgccsa5ek3ytlw9s2r5vwy',
		'"G Dood, veut être plus populaire que sur Twitter"',
	],
	[
		'npub199udz4c5vu9qhdpkkxkvrc6a7wk4nyu5c9cxj8n2gkjptzv4te3s7nlde5',
		'"Ambassadeur Blockchain Biel/Bienne"',
	],
	[
		'npub12ej3ve3aj820a7pyatkvh7wzvvdgmz3wlm5qfr99aesfteh9epps4vengl',
		'"purplepiller"',
	],
	[
		'npub1x8dzy9xegwmdk2vy30l8u08caspcqq2yzncxehdsa6kvnte9pr3qnt8pg4',
		'"purplepiller"',
	],
	[
		'npub1rcpq25a6mcs7yc9qyhgl769v58jm87qf4e2tksds5nwnduq4yq0s49d9a8',
		'"orangepiller et purplepiller"',
	],
	[
		'npub1dy7zsvk7jwd547xvmpptzlc9muhd64g7txvf60zwlxjyj4aj78as6hljz5',
		'"purplepiller"'
	]
];

const NoFollowersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

export default class OnboardingNotification extends Component {
  componentDidMount() {
    localState.get('noFollowers').on(this.inject());
    localState.get('hasNostrFollowers').on(this.inject());
    localState.get('showFollowSuggestions').on(this.inject());
    localState.get('showNoIrisToAddress').on(this.inject());
    localState.get('existingIrisToAddress').on(this.inject());
  }

  renderFollowSuggestions() {
    return (
      <div style="display:flex;flex-direction:column;flex:1">
        <p>{t('follow_someone_info')}</p>
        {SUGGESTED_FOLLOWS.map(([pub, description]) => (
          <div class="profile-link-container">
            <a href={`/${pub}`} className="profile-link">
              <div>
                <Identicon str={pub} width={40} />
              </div>
              <div style="flex: 1">
                <Name pub={pub} placeholder="Suggested follow" />
                <br />
                <small>{description}</small>
              </div>
            </a>
            <Follow id={pub} />
          </div>
        ))}
        <p>
          <PrimaryButton onClick={() => localState.get('showFollowSuggestions').put(false)}>
            {t('done')}
          </PrimaryButton>
        </p>
        <p>
          {t('alternatively')}
          <i> </i>
          <a href={`/${Key.toNostrBech32Address(Key.getPubKey(), 'npub')}`}>
            {t('give_your_profile_link_to_someone')}
          </a>
          .
        </p>
      </div>
    );
  }

  renderNoFollowers() {
    return (
      <NoFollowersWrapper>
        <CloseIconWrapper onClick={() => localState.get('noFollowers').put(false)}>
          <XMarkIcon width={24} />
        </CloseIconWrapper>
        <p>{t('no_followers_yet')}</p>
        <p>
          <Copy text={t('copy_link')} copyStr={Helpers.getMyProfileLink()} />
          <Button onClick={() => this.setState({ showQrModal: true })}>{t('show_qr_code')}</Button>
        </p>
        <small>{t('no_followers_yet_info')}</small>
        {this.state.showQrModal && (
          <QRModal onClose={() => this.setState({ showQrModal: false })} pub={Key.getPubKey()} />
        )}
      </NoFollowersWrapper>
    );
  }

  renderGetIrisAddress() {
    if (!this.state.existingIrisToAddress) {
      return (
        <div>
          <p>Get your own iris.to/username?</p>
          <p>
            <PrimaryButton onClick={() => route('/settings/iris_account')}>
              Yes please
            </PrimaryButton>
            <PrimaryButton onClick={() => localState.get('showNoIrisToAddress').put(false)}>
              No thanks
            </PrimaryButton>
          </p>
        </div>
      );
    }
  }

  render() {
    let content;
    if (this.state.showFollowSuggestions) {
      content = this.renderFollowSuggestions();
    } else if (this.state.showNoIrisToAddress) {
      content = this.renderGetIrisAddress();
    } else if (this.state.noFollowers) {
      content = this.renderNoFollowers();
    }

    if (content) {
      return (
        <div class="msg">
          <div class="msg-content">{content}</div>
        </div>
      );
    }
    return '';
  }
}
